import he from 'he';
import React from "react";
import {
    Box,
    ColumnLayout,
    SpaceBetween,
    Badge
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

const CRLF = "\r\n";

export const params = (dsn, type, overrides = {}) => {
    const params = new URLSearchParams();
    params.append('deviceserial', dsn);
    params.append('devicetype', type);
    Object.entries(overrides).forEach(([key, value]) => params.append(key, value));
    return params;
};

const prosodyRegex = /(<prosody.*?>)(.*?)(<\/prosody>)/g;
export const text = (ssml) => {
    let match, sb = '';
    let decoded = he.decode(ssml);
    // Add he
    while ((match = prosodyRegex.exec(decoded)) !== null) {
        sb += match[0];
    }
    return (sb || decoded).replace(/<[^>]*>/g, '');
}

export const deviceInfo = (device) => {
    return (
      <ColumnLayout columns={2} variant="text-grid">
          <SpaceBetween>
              <Box variant="awsui-key-label">Name</Box>
              <Box variant="p">{device.name}</Box>
          </SpaceBetween>
          <SpaceBetween>
              <Box variant="awsui-key-label">DSN</Box>
              <Box variant="p">{device.dsn}</Box>
          </SpaceBetween>
          <SpaceBetween>
              <Box variant="awsui-key-label">Type</Box>
              <Box variant="p">{device.type}</Box>
          </SpaceBetween>
          <SpaceBetween>
              <Box variant="awsui-key-label">Endpoint <Badge color="green">{ device.inOak() ? "with OAK" : "" }</Badge></Box>
              <Box variant="p">{device.endpoint}</Box>
          </SpaceBetween>
      </ColumnLayout>
    );
}

const response = (activities, metadata) => {
  let tts = _.chain(activities.ActivityItems)
    .values()
    .flatten()
    .filter({ ItemType: 'TTS' })
    .map(item => {
      const data = JSON.parse(item.ActivityItemData);
      return data.ttsText;
    })
    .value();
  if (!tts || !tts.length > 0) {
    tts = _.filter(
      _.get(metadata, "value.lifeCycleEventInfo.lifecycleEvents"), i => i.type === "ACTION_PLAN_RESULT"
    )
      .flatMap(i => i.actionPlanResult.actionResults)
      .filter(i => i.action.actionType.name.toLowerCase().includes("speechsynthesizer.speak"))
      .flatMap(i => i.action.arguments.list)
      .filter(i => i.name === "token")
      .flatMap(i => i.value)
      .join();
  }
  return (
    <Box variant="p">{ tts }</Box>
  );
};

export const actionTraceList = (metadata) => {
  if (metadata && typeof metadata === 'object') {
    return _.filter(
      _.get(metadata, "value.lifeCycleEventInfo.lifecycleEvents"), i => i.type === "ACTION_PLAN_RESULT"
    )
      .flatMap(i => i.actionPlanResult.actionResults)
      .flatMap(i => i.action)
      .map(i => { return { 'label': i.actionType.name, 'value': i.actionId.value } });
  }
  return [];
}

export const actionTrace = (metadata, actionId) => {
  if (metadata && typeof metadata === 'object') {
    return _.filter(
      _.get(metadata, "value.lifeCycleEventInfo.lifecycleEvents"), i => i.type === "ACTION_PLAN_RESULT"
    )
      .flatMap(i => i.actionPlanResult.actionResults)
      .filter(i => i.action.actionId.value === actionId)[0]
  }
  return [];
}

export const llmTrace = (device, activities, metadata) => {
  if (device) {
    return (
      <SpaceBetween size="m" direction="vertical">
        <ColumnLayout columns={4} variant="text-grid">
          <SpaceBetween>
            <Box variant="awsui-key-label">CustomerId</Box>
            <Box variant="p">{device.customerId}</Box>
          </SpaceBetween>
          <SpaceBetween>
            <Box variant="awsui-key-label">Name</Box>
            <Box variant="p">{device.name}</Box>
          </SpaceBetween>
          <SpaceBetween>
            <Box variant="awsui-key-label">DSN</Box>
            <Box variant="p">{device.dsn}</Box>
          </SpaceBetween>
          <SpaceBetween>
            <Box variant="awsui-key-label">Type</Box>
            <Box variant="p">{device.type}</Box>
          </SpaceBetween>
        </ColumnLayout>
        { metadata && typeof metadata === 'object' && (
          <ColumnLayout columns={1} variant="text-grid">
            <SpaceBetween>
              <Box variant="awsui-key-label">UtteranceId</Box>
              <Box variant="p"> {_.get(metadata, "value.utteranceInfo.utteranceId")}</Box>
            </SpaceBetween>
            <SpaceBetween>
              <Box variant="awsui-key-label">Utterance</Box>
              <Box variant="p"> {_.get(metadata, "value.utteranceInfo.utterance")}</Box>
            </SpaceBetween>
            <SpaceBetween>
              <Box variant="awsui-key-label">Response</Box>
              {response(activities, metadata)}
            </SpaceBetween>
          </ColumnLayout>
        )}
      </SpaceBetween>
    );
  }
  return (<div/>);
}

export const toBase64 = (str) => {
  const encoder = new TextEncoder();
  const utf8Array = encoder.encode(str);
  let binaryString = '';
  utf8Array.forEach(byte => {
    binaryString += String.fromCharCode(byte);
  });
  return btoa(binaryString);
}

export const directive = (jsonContent = {}) => {
  // Create multipart directive
  let builder = [];
  builder.push("Content-Type: application/json");
  builder.push(CRLF);
  builder.push(CRLF);
  builder.push(jsonContent);
  builder.push(CRLF);
  builder.push("--------abcde123");
  builder.push(CRLF);

  let multipart = builder.join("");
  let encoded = toBase64(toBase64(multipart)); // Temp until CR CR-155094466

  return btoa(JSON.stringify({
    type: "directive",
    messageId: uuidv4(),
    clearQ: false,
    directive: encoded
  }));
}
