import _ from 'lodash';
import { params } from "./util";
import { HexClient, AbesClient } from './client';
import { AvsApi } from "./avsapi";
import { History } from "./history";
import { v4 as uuidv4 } from 'uuid';
import { directive } from './util';

export function Device(customerId, deviceInfo) {
    this.customerId = customerId;
    this.dsn = _.get(deviceInfo, 'dsn');
    this.type = _.get(deviceInfo, 'deviceType');
    this.name = _.get(deviceInfo, 'name');
    this.endpoint = _.get(deviceInfo, 'endpoint');
    this.inOak = () => this.endpoint && (this.endpoint.includes('avsqa') || this.endpoint.includes('aqt-gateway'));
    this.inGamma = () => this.endpoint && this.endpoint.includes('gamma');
    this.env = () => this.inGamma() ? "gamma" : "prod";

    let history = new History(this.dsn, this.type, this.env);
    this.history = () => history;
    let avsapi = new AvsApi(this.dsn, this.type, history);
    this.avsapi = () => avsapi;

    const where = async () => {
        const query = params(this.dsn, this.type);
        try {
            const resp = await HexClient.deviceendpoint(query, "prod");

            if (_.get(resp, 'status') === 200) {
                this.endpoint = _.get(resp, 'data.Endpoint');
            }
        } catch (err) {
            try {
                const resp = await HexClient.deviceendpoint(query, "gamma");
                if (_.get(resp, 'status') === 200) {
                    this.endpoint = _.get(resp, 'data.Endpoint');
                }
            } catch(err) {
                if (!/409|404/.test(err.message)) {
                    console.log(`Cannot get device location ${err}`);
                }
                this.endpoint = null;
            }
        }
        return this.endpoint;
    };

    const endpoint = async (endpoint, env, throughProxy = false) => {
        try {
            const query = params(this.dsn, this.type, {'endpoint': endpoint});
            let resp;
            if (throughProxy) {
                resp = await HexClient.setendpoint(query, env);
            } else {
                resp = await HexClient.switchendpoint(query, env);
            }
            await new Promise(resolve => setTimeout(resolve, 5000));

            if (_.get(resp, 'status') === 204 || _.get(resp, 'status') === 200) {
                this.endpoint = await where();
            }
        } catch (err) {
            console.log(`Cannot switch device location ${err}`);
            this.endpoint = null;
        }
        return this.endpoint;
    }

    const senddirective = async (data) => {
        try {
            const payload = {
                deviceType: this.type,
                deviceId: this.dsn,
                customerId: this.customerId,
                payload: data
            }
            await AbesClient.senddirective(payload);
        } catch (err) {
            console.log(`SendDirective to device failed ${err}`);
        }
    }

    this.suggest = async (prompt, modelid) => {
        try {
            let hist = this.history()
                .featureItems()
                .slice(-10)
                .map((step) => `Q: ${step.Q}\nA:${step.A}`)
                .join('\n');
            let params = new URLSearchParams();
            params.append("prompt", prompt);
            params.append("modelid", modelid);
            params.append("stmt", hist);

            const resp = await HexClient.bedrockgenerate(params);

            if (_.get(resp, 'status') === 200) {
                return _.get(resp, 'data.response');
            }
        } catch (err) {
            console.log(`Cannot generate a suggestion ${err}`);
        }
        return false;
    }

    this.injectspeech = async (text, addhistory = true) => {
        if (this.inOak()) {
            return await avsapi.injectspeech(text, this.env(), addhistory)
        }
        return false;
    }

    this.injectmultiturn = async (texts, interrupt = () => false, addhistory = true) => {
        if (this.inOak()) {
            return await avsapi.injectmultiturn(texts, this.env(), interrupt, addhistory)
        }
        return false;
    }

    this.startstream = async () => {
        if (this.inOak()) {
            await avsapi.initialize(this.env());
        }
    }

    this.endstream = async () => {
        await avsapi.clear();
    }

    this.switch = async (url) => {
        let status = false;
        const payload = {
            directive: {
                header: {
                    namespace: "Alexa.ApiGateway",
                    name: "SetGateway",
                    messageId: uuidv4(),
                },
                payload: {
                    gateway: url
                }
            }
        }
        if (this.inOak()) { // Device in OAK
            if (this.endpoint.includes("avsqa")) {
                status = await senddirective(JSON.stringify(payload)); // Switch to requested Endpoint
            } else {
                status = await endpoint(url, this.env(), true);
            }
        } else {
            if (!this.inGamma()) {
                status = await endpoint("https://aqt-gateway.iad.amazon-aqt.com", "prod", false);
                status = await endpoint(url, "prod", true);
            } else {
                status = await endpoint("https://aqt-gateway.gamma.iad.amazon-aqt.com", "gamma", false);
                status = await endpoint(url, "gamma", true);
            }
        }
        this.endpoint = await where();
        return status;
    }

    this.injectdirective = async (content) => {
        const payload = directive(content);
        return await HexClient.injectdirective(params(this.dsn, this.type), {'request': payload}, this.env());
    }
}
