import React, {useEffect, useState} from "react";
import {
  Input,
  Container,
  Button,
  Grid,
  SpaceBetween,
  Modal,
  Textarea,
  Alert,
  Box
} from '@amzn/open-automation-kit-ui/node_modules/@amzn/awsui-components-react-v3/polaris';
import { findLast } from 'lodash';
import { TYPE } from "./history";

const READY = "Ready";

function Panel({ device, setApl }) {
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState();
  const [invalid, setInvalid] = useState(false);
  const [settings, setSettings] = useState();
  const [mode, setMode] = useState();
  const [message, setMessage] = useState(READY);
  const [batchInput, setBatchInput] = useState([]);
  const [batchVisible, setBatchVisible] = useState(false);
  const [directiveInput, setDirectiveInput] = useState([]);
  const [directiveVisible, setDirectiveVisible] = useState(false);

  useEffect(async () => {
    // An utterance is not in progress, settings and mode are defined and mode is sugegst turned on
    if (!loading && settings && mode && mode.suggest) {
      setMessage("Generating suggestion...");
      const suggestion = await device.suggest(settings.prompt, settings.option.value);
      input || setInput(suggestion);
      setMessage(READY);
    }
  }, [mode, loading, settings]);

  useEffect(async () => {
    // An utterance is not in progress, settings and mode are defined and mode is sugegst turned on
    if (!loading && mode && mode.auto && input) {
      setMessage("Sending suggestion...");
      await new Promise(resolve => setTimeout(resolve, 3000));
      handleSend();
    }
  }, [mode, loading, input]);

  useEffect(() => {
    if (!loading) {
      setMessage(READY);
    }
  }, [loading]);

  useEffect(() => {
    if (device) {
      const unsub = device.history().subscribe(stateChange);
      return () => unsub();
    }
  }, [device]);

  const stateChange = (h) => {
    setLoading((loading) => {
      if (loading || batchInput) {
        let last = findLast(h.history(), item => item.type !== TYPE.ALEXA);
        return last && [TYPE.PENDING, TYPE.PROCESSING].includes(last.type);
      }
      return loading;
    });
  }

  const handleSend = (e) => {
    e && e.preventDefault();
    if (input && input.match(/\b\w+\b/g).length > 0) {
      setLoading(true);
      setApl(false);
      setMessage("Sending utterance...");
      device.injectspeech(input);
      setMessage("Timed wait for Speak directive & SpeechFinished event...");
      setInput(null);
    } else {
      setInvalid(true);
    }
  }

  const handleBatch = () => {
   if (batchInput && batchInput.length > 0) {
     setLoading(true);
     let items = batchInput.split('\n');
     device.injectmultiturn(items).then(() => setBatchInput(null));
   }
   setBatchVisible(false);
  }

  const handleDirective = () => {
    if (directiveInput && directiveInput.length > 0) {
      setLoading(true);
      device.injectdirective(directiveInput)
        .then(() => setDirectiveInput(null))
        .then(() => window.alert("Directive sent"))
        .catch(err => window.alert(err));
    }
    setDirectiveVisible(false);
    setLoading(false);
  }

  const genModal = (visible, setVisible, input, setInput, handle, header, guide, alert) => {
    return (
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        size="medium"
        header={header}
      >
        <SpaceBetween direction="vertical" size="m">
          <Textarea
            onChange={({ detail }) => setInput(detail.value)}
            value={input}
            placeholder={guide}
            rows={10}
          />
          <Box textAlign="center">
            <Button onClick={() => handle()} wrapText={false} disabled={!input || !input.length > 0}>Send</Button>
          </Box>
          <Alert statusIconAriaLabel="Info">{alert}</Alert>
        </SpaceBetween>
      </Modal>
    )
  }

  return (
    <Container>
      {
        genModal(batchVisible, setBatchVisible, batchInput, setBatchInput, handleBatch,
        "Ask Alexa",
        "To create a multi-turn conversation, enter each utterance on a new line.",
        "Use the Batch feature for a complete multi-turn conversation. If Alexa does not enter Listening mode after each utterance, the sequence will not continue."
        )
      }
      {
        genModal(directiveVisible, setDirectiveVisible, directiveInput, setDirectiveInput, handleDirective,
          "Inject Directive",
          "To send a directive to the device, enter the directive json",
          "Use the Directive feature to inject a directive into the device. Only delivery status is confirmed; no indication is provided if the device doesn't handle it."
        )
      }
      <Grid
        gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}
      >
        <Input
          spellCheck
          autoFocus
          onChange={(e) => {
            setInvalid(false);
            setInput(e.detail.value);
          }}
          onKeyDown={(e) => {
            if (e.detail.key === "Enter" && !e.shiftKey && !loading) {
              handleSend(e);
            }
          }}
          invalid={invalid}
          value={input}
          placeholder={"Ask Alexa"}
          disabled={mode && mode.auto}
        />
        <SpaceBetween direction="horizontal" size="m">
          <Button
            onClick={handleSend}
            loading={loading}
            disabled={mode && mode.auto}
            wrapText={false}
          > Send </Button>
          <Button
            onClick={() => setDirectiveVisible(true)}
            loading={loading}
            disabled={mode && mode.auto}
            wrapText={false}
          > Directive </Button>
        </SpaceBetween>
      </Grid>
    </Container>
  )
}

export default React.memo(Panel);
